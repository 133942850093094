import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Exit_delete_room from "Services/Functions/exit_delete_room";
import fetch_room_data from "Services/Functions/fetch_room_data";
import send_message from "Services/Functions/send_message";
import "Styles/Room.css";

export default function Room({ Socket }) {
  const { RoomID } = useParams();

  const effectRan = useRef(true);
  const chatContainerRef = useRef(null);
  const msgFocus = useRef();

  const navigate = useNavigate();

  const [Message, setMessage] = useState("");
  const [roomInfo, setRoomInfo] = useState({
    Name: "",
    Messages: [],
  });

  useEffect(() => {
    if (effectRan.current === true) {
      const initializeRoom = async () => {
        await fetch_room_data(RoomID, setRoomInfo);
      };
      initializeRoom();
      effectRan.current = false;
    }
  }, [RoomID]);

  useEffect(() => {
    if (Socket) {
      Socket.on("chat message received", (message) => {
        const parsedMessage = JSON.parse(message);
        const new_message_format = {
          Encrypted_message: parsedMessage.Message,
          Original_message: parsedMessage.Original_message,
          Sender: parsedMessage.Sender,
          showOriginal: false,
        };
        setRoomInfo((prevRoomInfo) => ({
          ...prevRoomInfo,
          Messages: [new_message_format, ...prevRoomInfo.Messages],
        }));
      });
      return () => {
        Socket.off("chat message received");
      };
    }
  }, [Socket]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [roomInfo.Messages]);

  const sender = sessionStorage.getItem("you_are");

  const handleCopy = () => {
    navigator.clipboard
      .writeText(RoomID)
      .then(() => {
        alert("Room ID copied to clipboard: " + RoomID);
      })
      .catch((err) => {
        alert("something went wrong");
        console.error("Failed to copy Room ID: ", err);
      });
  };

  const handleLeave = (e) => {
    e.preventDefault();
    Exit_delete_room(RoomID, navigate);
  };

  const handleToggle = (index) => {
    msgFocus.current.focus();
    setRoomInfo((prevRoomInfo) => {
      const newMessages = prevRoomInfo.Messages.map((message, i) => {
        if (i === index) {
          return {
            ...message,
            showOriginal: !message.showOriginal,
          };
        }
        return message;
      });
      return { ...prevRoomInfo, Messages: newMessages };
    });

    setTimeout(() => {
      setRoomInfo((prevRoomInfo) => {
        const newMessages = prevRoomInfo.Messages.map((message, i) => {
          if (i === index) {
            return {
              ...message,
              showOriginal: false,
            };
          }
          return message;
        });
        return { ...prevRoomInfo, Messages: newMessages };
      });
    }, 30000);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (!Socket) {
      console.error("WebSocket connection is not established");
      return;
    }
    if (!Message) return;
    try {
      setMessage("");
      msgFocus.current.focus();
      await send_message(Message);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (Message === "") {
      return;
    }
    if (!isMobile && e.key === "Enter") {
      handleSend(e);
    } else if (isMobile && e.key === "Enter") {
      e.preventDefault();
      setMessage((prevMessage) => prevMessage + "\n");
    }
  };

  return (
    <React.Fragment>
      <div className="RoomContainer">
        <div className="RoomTopBar">
          <h4 className="RoomName">
            <Link className="backToHome" to="/home">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </Link>
            {roomInfo.Name}
          </h4>
          <div className="RoomBtnContainer">
            <div className="RoomTopBtns" onClick={handleCopy}>
              <ion-icon name="copy-outline" className="RoomTopBtn"></ion-icon>
            </div>
            <div className="RoomTopBtns" onClick={handleLeave}>
              <ion-icon name="exit-outline" className="RoomTopBtn"></ion-icon>
            </div>
          </div>
        </div>
        <div className="chat-msgs-container" ref={chatContainerRef}>
          {roomInfo.Messages.map((message, index) => (
            <div
              key={index}
              className={
                message.Sender === sender ? "chat-msg-sen" : "chat-msg-rec"
              }
            >
              <h4>{message.Sender}</h4>
              <input
                type="checkbox"
                className="switch"
                checked={message.showOriginal}
                onChange={() => handleToggle(index)}
              />
              <p>
                {message.showOriginal
                  ? message.Original_message
                  : message.Encrypted_message}
              </p>
            </div>
          ))}
        </div>
        <div className="MessageContainer">
          <textarea
            rows="1"
            className="messageToSend"
            type="text"
            placeholder="Message"
            value={Message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={msgFocus}
          />
          <div className="sendBtn" onClick={handleSend}>
            <ion-icon name="send-outline"></ion-icon>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
