import axios from "axios";
import Cookies from "js-cookie";
import domain from "Utilities/Domain";

async function send_message(Message) {
  try {
    const token = Cookies.get("token");
    const response = await axios.post(
      `${domain}/send_message`,
      { Message },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      return;
    }
  } catch (error) {
    if (error.response.status === 500) {
      console.error("Internal server error");
    } else if (error.response.status === 404) {
      alert("Room not found!");
    } else if (error.response.status === 401) {
      alert("You are not a member in this room!");
    }
  }
}

export default send_message;
