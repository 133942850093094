import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "Styles/Create.css";
import Loader from "Components/Loader";
import { Helmet } from "react-helmet-async";
import create_room from "./Functions/create_room";
import Navbar from "Components/Navbar";

function CreateRoom({ setSocket }) {
  const navigate = useNavigate();

  const [data, setData] = useState({
    Room_Name: "",
    Room_Admin: "",
    Room_Password: "",
    Room_Enc_Type: "Ascii",
  });
  const [loading, setLoading] = useState(false);

  const handleDataChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleEncTypeChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    create_room(setLoading, data, navigate, setSocket);
  };

  return (
    <React.Fragment>
      <Navbar />
      <Helmet>
        <title>CR | Create Room</title>
      </Helmet>
      <div className="create-container">
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="form-title">
            <h1>Create Room</h1>
          </div>
          <div className="inputField">
            <input
              required
              type="text"
              name="Room_Name"
              className="input"
              placeholder="Room Name"
              onChange={handleDataChange}
            />
          </div>
          <div className="inputField">
            <input
              required
              type="text"
              name="Room_Admin"
              className="input"
              placeholder="Nickname"
              onChange={handleDataChange}
            />
          </div>

          <div className="inputField">
            <input
              required=""
              type="password"
              name="Room_Password"
              className="input"
              placeholder="Password"
              onChange={handleDataChange}
            />
          </div>
          <div className="options">
            <h3>Type of encryption</h3>
            <div className="body">
              <div className="tabs">
                <input
                  value="Ascii"
                  name="Room_Enc_Type"
                  id="ascii"
                  type="radio"
                  className="input"
                  onChange={handleEncTypeChange}
                />
                <label htmlFor="ascii" className="label">
                  Ascii
                </label>
                <input
                  value="Binary"
                  name="Room_Enc_Type"
                  id="binary"
                  type="radio"
                  className="input"
                  onChange={handleEncTypeChange}
                />
                <label htmlFor="binary" className="label">
                  Binary
                </label>
                <input
                  value="Caeser"
                  name="Room_Enc_Type"
                  id="caeser"
                  type="radio"
                  className="input"
                  onChange={handleEncTypeChange}
                />
                <label htmlFor="caeser" className="label">
                  Caeser
                </label>
              </div>
            </div>
          </div>
          <div className="inputField">
            <button type="submit" className="submit-btn">
              {loading ? Loader() : "Create"}
            </button>
          </div>
          <div className="cr-note">
            <span><b>NOTE:</b> If got internal server error on create, retry clicking Create button.</span>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default CreateRoom;
