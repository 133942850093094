import React from "react";
import { Link } from "react-router-dom";
import logo from "Assets/Images/logo.png";
import "Styles/Navbar.css";

export default function Navbar() {
  return (
    <>
      <div className="NavbarContainer">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="links">
          <Link className="link" to="/home">
            Home
          </Link>
          <Link className="link" to="/create">
            Create Room
          </Link>
          <Link className="link" to="/join">
            Join Room
          </Link>
          <Link className="link" to='/about'>About Us</Link>
        </div>
        <div className="links-res">
          <Link className="link" to="/home">
            <ion-icon name="home-outline"></ion-icon>
          </Link>
          <Link className="link" to="/create">
            <ion-icon name="add-outline"></ion-icon>
          </Link>
          <img src={logo} className="logo-res" alt="logo" />
          <Link className="link" to="/join">
            <ion-icon name="enter-outline"></ion-icon>
          </Link>
          <Link className="link" to="/about">
            <ion-icon name="information-circle-outline"></ion-icon>
          </Link>
        </div>
      </div>
    </>
  );
}
