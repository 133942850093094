import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "Styles/Home.css";
import HowToUse from "Assets/Videos/HowToUse.mp4";
import HowToUseMobile from "Assets/Videos/HowToUseMobile.mp4";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import Navbar from "Components/Navbar";
import thumb from "Assets/Images/Tutorial.png";
import MobileThumb from "Assets/Images/mobile-thumb.png"

export default function Home() {
  const navigate = useNavigate();

  function Navigate(path) {
    navigate(`/${path}`);
  }

  return (
    <React.Fragment>
      <Navbar />
      <Helmet>
        <title>CR | Talk Privately</title>
        <meta
          name="CrypticRoom"
          content="Talk Privately, Encrypted Messaging, Secret Chats"
        />
      </Helmet>
      <div className="HomeContainer">
        <div className="home-wrapper">
          <div className="home-text">
            <h1>
              Welcome to the <b>best</b>
              <br />
              Private Chatting Rooms
            </h1>
            <h2>we offer you the best privacy in chatting</h2>
            <div className="home-btns">
              <button type="button" onClick={() => Navigate("create")}>
                Create Room
              </button>
              <button type="button" onClick={() => Navigate("join")}>
                Join Room
              </button>
            </div>
          </div>
          <div className="home-tutorial">
            <h2>How to use:</h2>
            <Video autoPlay poster={thumb}>
              <source src={HowToUse} type="video/webm" />
            </Video>
          </div>
          <div className="home-tutorial-mobile">
            <h2>How to use:</h2>
            <Video autoPlay poster={MobileThumb}>
              <source src={HowToUseMobile} type="video/webm" />
            </Video>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
