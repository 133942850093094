import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Room from "Pages/Room";
import "Styles/Rooms.css";
import fetch_room from "Services/Functions/fetch_room";
import Cookies from "js-cookie";

export default function Rooms({ Socket }) {
  const { RoomID } = useParams();
  const navigate = useNavigate();

  const stopRender = useRef(true);
  const roomDeleted = useRef(false);

  const Chat_Socket = Socket;

  const [roomDetails, setRoomDetails] = useState({
    Name: "",
    Admin: "",
    Members: [],
  });

  useEffect(() => {
    if (stopRender.current === true) {
      const initializeRoom = async () => {
        await fetch_room(RoomID, setRoomDetails);
      };
      initializeRoom();
      stopRender.current = false;
    }
  }, [RoomID]);

  useEffect(() => {
    if (Socket) {
      //On user join
      Socket.on("user joined", (user) => {
        const parsedUser = JSON.parse(user);
        setRoomDetails((prevRoomDetails) => {
          const isUserPresent = prevRoomDetails.Members.some(
            (member) => member === parsedUser.Room_Member
          );
          if (!isUserPresent) {
            return {
              ...prevRoomDetails,
              Members: [...prevRoomDetails.Members, parsedUser.Room_Member],
            };
          }
          return prevRoomDetails;
        });
      });

      //on user left

      Socket.on("user left", (user) => {
        const parsedUser = JSON.parse(user);
        if (parsedUser.RoomID === RoomID) {
          const isAdmin = parsedUser.isAdmin;
          if (isAdmin) {
            if (!roomDeleted.current) {
              Socket.off("chat message received");
              Socket.off("user joined");
              Socket.off("user left");
              Socket.disconnect();
              alert("Room deleted");
              navigate("/home", { replace: true });
              Cookies.remove("token");
              sessionStorage.clear();
              roomDeleted.current = true;
            }
          } else {
            // Update room details by removing the leaving user from the Members array
            setRoomDetails((prevRoomDetails) => ({
              ...prevRoomDetails,
              Members: prevRoomDetails.Members.filter(
                (member) => member !== parsedUser.User
              ),
            }));
            Socket.disconnect();
          }
        }
      });
    }
  }, [Socket, RoomID, navigate]);

  const currentUser = sessionStorage.getItem("you_are");

  return (
    <React.Fragment>
      <Helmet>
        <title>CR | {roomDetails.Name}</title>
      </Helmet>
      <div className="chat-container">
        <div className="chat-body">
          <div className="Rooms">
            <div className="Rooms-Header">
              <Link className="backToHome" to="/home">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </Link>
              <h2> Room</h2>
            </div>
            <div className="tabs-container">
              <div className="Room-tab">
                <h4 className="room-name">
                  {roomDetails.Name} <div className="new-msg"></div>
                </h4>
                <div className="room-content">
                  {roomDetails.Admin}(Admin)
                  {roomDetails.Admin === currentUser && "(you)"}
                  {roomDetails.Members.map((member, index) => (
                    <p key={index}>
                      {member}
                      {member === currentUser && " (you)"}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="chat-room">
            <Room Socket={Chat_Socket} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
