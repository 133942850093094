import get_room from "./get_room";

async function fetch_room(RoomID, setRoomDetails) {
  try {
    const room_data = await get_room(RoomID);
    if (room_data.success) {
      setRoomDetails({
        ...setRoomDetails,
        Name: room_data.RoomName,
        Admin: room_data.RoomAdmin,
        Members: room_data.RoomMembers,
      });
    }
  } catch (error) {
    console.error("Error fetching room:", error);
  }
}

export default fetch_room;
