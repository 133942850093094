import get_room from "./get_room";

async function fetch_room_data(RoomID, setRoomInfo) {
  try {
    const room_data = await get_room(RoomID);
    if (room_data.success) {
      setRoomInfo({
        Name: room_data.RoomName,
        Messages: room_data.RoomMessages.map((message) => ({
          Encrypted_message: message.Encrypted_message,
          Original_message: message.Original_message,
          Sender: message.Sender,
          showOriginal: false,
        })),
      });
    }
  } catch (error) {
    console.error("Error fetching room:", error);
  }
}

export default fetch_room_data;
