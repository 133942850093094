import axios from "axios";
import Cookies from "js-cookie";
import domain from "Utilities/Domain";
import connectWebSocket from "Services/WebSocket";

async function join_room(setLoading, data, navigate, setSocket) {
  setLoading(true);
  try {
    const response = await axios.post(`${domain}/join_room`, data);
    if (response.status === 201) {
      sessionStorage.setItem("you_are", data.Room_Member);
      Cookies.set("token", response.data.Token, { expires: 1 });
      alert("Room Joined");
      await connectWebSocket(setSocket, response.data.Token);
      navigate(`/room/${response.data.RoomID}`);
    }
  } catch (error) {
    if (error.response.status === 404) {
      return alert("Room not found!");
    } else if (
      error.response.status === 401 &&
      error.response.data.message === "Incorrect password"
    ) {
      return alert("Incorrect password!");
    } else if (
      error.response.status === 401 &&
      error.response.data.message === "Existed member"
    ) {
      return alert("Nickname used");
    }
  } finally {
    setLoading(false);
  }
}

export default join_room;
