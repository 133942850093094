import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Footer from "Components/Footer";
import Home from "Pages/Home";
import About from "Pages/About"
import JoinRoom from "Services/Join_room";
import CreateRoom from "Services/Create_room";
import Rooms from "Components/Rooms";
import NotFound from "Pages/404";
import connectWebSocket from "Services/WebSocket";
import Cookies from "js-cookie";

function App() {
  const stopRender = useRef(true);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (stopRender.current === true) {
      const token = Cookies.get("token");
      if (token) {
        const initializeSocket = async () => {
          await connectWebSocket(setSocket);
        };
        initializeSocket();
      }

      stopRender.current = false;
      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
  }, [socket]);

  return (
    <React.Fragment>
      <BrowserRouter>
        <HelmetProvider>
          
          <Routes>
            <Route exact path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/join" element={<JoinRoom setSocket={setSocket} />} />
            <Route
              path="/create"
              element={<CreateRoom setSocket={setSocket} />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/room/:RoomID" element={<Rooms Socket={socket} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </HelmetProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
