import React from 'react';
import "Styles/Loader.css";

function Loader() {
  return (
      <div className="loader">
          <div className="loader-inner">
              <div className="loader-block"></div>
              <div className="loader-block"></div>
              <div className="loader-block"></div>
              <div className="loader-block"></div>
          </div>
      </div>
  )
}


export default Loader