import domain from "Utilities/Domain";
import Cookies from "js-cookie";
import axios from "axios";

async function Exit_delete_room(room_id, navigate) {
  try {
    const token = Cookies.get("token");

    const response = await axios.delete(
      `${domain}/leave_room?room_id=${room_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      if (response.data.Done === "User") {
        alert("You left the room");
        navigate("/home", { replace: true });
      }
      if (response.data.Done === "Room") {
        navigate("/home", { replace: true });
      }
      Cookies.remove("token");
      sessionStorage.clear();
    }
  } catch (error) {
    if (error.response.status === 500) {
      alert("Internal server error");
    } else if (error.response.status === 404) {
      alert("Room not found");
    } else if (error.response.status === 401) {
      if (error.response.data.Details === "No Room") {
        alert("Something went wrong");
      } else if (error.response.data.Details === "Not a member") {
        alert("You are not a member is this room");
      }
    }
  }
}

export default Exit_delete_room;
