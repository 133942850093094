import React from 'react'
import Navbar from 'Components/Navbar';
import { Helmet } from 'react-helmet-async';
import "Styles/About.css";
function About() {
    return (
        <React.Fragment>
            <Helmet>
                <title>CR | About Us</title>
                <meta
                    name="CrypticRoom"
                    content="Talk Privately, Encrypted Messaging, Secret Chats"
                />
            </Helmet>
            <Navbar />
            <div className='void' id='void'>
                <div className='crop'>
                    <ul>
                        <li>
                            <div className='card'>
                                <span>
                                    <span className='model-name'>Our App</span>
                                    <span>
                                        Cryptic Room is an Online Chat Application that lets you stay in touch with your friends, family and all who you need to text anytime - anywhere.<br />
                                        Cryptic Room is free and offers simple, secure, reliable ENCRYPTED messaging all around the world.
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className='card'>
                                <span>
                                    <span className='model-name'>Our Mission</span>
                                    <span>
                                        Cryptic Room serves as an alternative SMS application, but with a little bit of spices on the top!<br />
                                        This product give the user the experience of safe and secure chatting, using the feature of Ecrypted Messaging. <br />
                                        Also adding the touch of end-to-end encryption into our product.
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className='card'>
                                <span>
                                    <span className='model-name'>Our Team</span>
                                    <span>
                                        Abdallah El Sayed Ahmad : Full-Stack<br />
                                        George Ters   : Backend<br />
                                        Hassan Assaad : Frontend
                                    </span>
                                </span>
                            </div>
                        </li>

                    </ul>
                    <div className='last-circle'></div>
                    <div className='second-circle'></div>
                </div>
                <div className='mask'>
                    <div className='mask-txt'>There is Always more to know,<br />Check it out!</div>
                </div>
                <div className='center-circle'>
                    <div className='center-circle-txt'>About Us</div>
                </div>
            </div>
            <div className='void-mobile'>
                <div class="seven">
                    <h1>About Us</h1>
                </div>
                <div className='about-cards'>
                    <div className='about-card'>
                        <span className='about-card-name'>Our App</span>
                        <span className='about-card-text'>
                            <p>Cryptic Room is an Online Chat Application that lets you stay in touch with your friends, family and all who you need to text anytime - anywhere.</p>
                            <p>Cryptic Room is free and offers simple, secure, reliable ENCRYPTED messaging all around the world.</p>
                        </span>
                    </div>
                    <div className='about-card'>
                        <span className='about-card-name'>Our Mission</span>
                        <span className='about-card-text'>
                            <p>Cryptic Room serves as an alternative SMS application, but with a little bit of spices on the top!</p>
                            <p>This product give the user the experience of safe and secure chatting, using the feature of Ecrypted Messaging. </p>
                            <p>Also adding the touch of end-to-end encryption into our product.</p>
                        </span>
                    </div>
                    <div className='about-card'>
                        <span className='about-card-name'>Our Team</span>
                        <span className='about-card-text'>
                            Abdallah El Sayed Ahmad : Full-Stack<br />
                            George Ters   : Backend<br />
                            Hassan Assaad : Frontend
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default About