import { io } from "socket.io-client";
import Cookies from "js-cookie";

async function connectWebSocket(setSocket, token = null) {
  if (!token) {
    token = Cookies.get("token");
  }

  if (!token) {
    console.error("No token available, cannot connect to WebSocket.");
    return null;
  }

  const socket = io("/", {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  setSocket(socket);
  return socket;
}

export default connectWebSocket;
