import axios from "axios";
import domain from "Utilities/Domain";

async function get_room(room_id) {
  try {
    const response = await axios.get(`${domain}/room?room_id=${room_id}`);
    if (response.status === 200) {
      const filtered_members = response.data.Members.filter(
        (member) => member !== response.data.Admin
      );
      return {
        success: true,
        RoomName: response.data.Name,
        RoomAdmin: response.data.Admin,
        RoomMembers: filtered_members,
        RoomMessages: response.data.Messages,
      };
    }
  } catch (error) {
    if (error.response.status === 500) {
      alert("Internal server error");
    }
  }
}
export default get_room;
