import React from "react";
import { Link } from "react-router-dom";
import "Styles/404.css";
import NOTFOUND from "Assets/Images/NOTFOUND.svg";
import Navbar from "Components/Navbar";

function NotFound() {
  return (
    <React.Fragment>
      <Navbar />
      <div className="Container404">
        
        <img src={NOTFOUND} alt="404" id="img-404" />
        <h1 id="title-404">PVGE N0T F0UND!</h1>
        <Link id="link-404" to="/home">Go back to Home</Link>
      </div>
    </React.Fragment>
  );
}

export default NotFound;
