import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import join_room from "./Functions/join_room";
import "Styles/Join.css";
import Navbar from "Components/Navbar";
import Loader from "Components/Loader";

export default function JoinRoom({ setSocket }) {
  const navigate = useNavigate();

  const [data, setData] = useState({
    Room_ID: "",
    Room_Password: "",
    Room_Member: "",
  });

  const [loading, setLoading] = useState(false);

  const handleDataCHange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    join_room(setLoading, data, navigate, setSocket);
  };

  return (
    <React.Fragment>
      <Navbar />
      <Helmet>
        <title>CR | Join Room</title>
      </Helmet>
      <div className="join-container">
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="form-title">
            <h1>Join Room</h1>
          </div>
          <div className="inputField">
            <input
              required
              type="text"
              name="Room_Member"
              className="input"
              placeholder="Nickname"
              onChange={handleDataCHange}
            />
          </div>
          <div className="inputField">
            <input
              required
              type="text"
              name="Room_ID"
              className="input"
              placeholder="Room ID"
              onChange={handleDataCHange}
            />
          </div>
          <div className="inputField">
            <input
              required
              type="password"
              name="Room_Password"
              className="input"
              placeholder="Password"
              onChange={handleDataCHange}
            />
          </div>
          <div className="inputField">
            <button type="submit" className="submit-btn">
              {loading ? Loader() : "Join"}
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
