import axios from "axios";
import Cookies from "js-cookie";
import domain from "Utilities/Domain";
import connectWebSocket from "Services/WebSocket";

async function create_room(setLoading, data, navigate, setSocket) {
  setLoading(true);
  try {
    const response = await axios.post(`${domain}/create_room`, data);
    if (response.status === 201) {
      Cookies.set("token", response.data.Token, { expires: 1 });
      sessionStorage.setItem("you_are", data.Room_Admin);
      alert("Room Created successfully");
      await connectWebSocket(setSocket, response.data.Token);
      navigate(`/room/${response.data.RoomID}`);
    }
  } catch (error) {
    if (error.response.status === 500) {
      return alert("Internal server problem");
    }
  } finally {
    setLoading(false);
  }
}

export default create_room;
