import React from "react";
import "Styles/Footer.css";
import footerLogo from "Assets/Images/footerLogo.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <React.Fragment>
      <div className="FooterContainer">
        <div className="footer-logo">
          <img src={footerLogo} alt="logo" />
          <span className="copyright">
            Copyright &copy; Cryptic Room | All Rights Reserved.
          </span>
        </div>
        <div className="info">
          <Link>&rarr; Want to know more about us?</Link>
          <Link>&rarr; Help, or support?</Link>
          <Link>&rarr; Community guidelines</Link>
          <Link>&rarr; FAQ</Link>
        </div>
        <div className="contacts">
          <span>
            <ion-icon name="call-outline"></ion-icon> +961 71 881 429
          </span>
          <span>
            <ion-icon name="mail-outline"></ion-icon> support@Cryptic.com
          </span>
          <span>
            <ion-icon name="git-branch-outline"></ion-icon>{" "}
            @github.com/CrypticRoom
          </span>
        </div>
        <div className="footer-btn-wrapper">
          <button type="button" className="footer-btn">
            Contact Us
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
